import { Component, ElementRef, Inject } from '@angular/core';
import { Helper } from './shared/helper';
import { CartService } from './shared/cart.service';
import { LoaderService } from './shared/loader.service';
import { DOCUMENT } from '@angular/common';
import { DeliverySettingService } from './shared/delivery-setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CartService, DeliverySettingService],
})
export class AppComponent {
  constructor(
    public delivery_setting_service: DeliverySettingService,
    public loader_service: LoaderService,
    public cart_service: CartService,
    @Inject(DOCUMENT) private _documentRef: any,
    private elementRef: ElementRef,
    public helper: Helper
  ) {
    helper.trans.addLangs(['fr', 'en']);

    var index = localStorage['language'];
    if (index) {
      index = JSON.parse(index);
    } else {
      index = '0';
      // localStorage.setItem('language', '0')
    }
    helper.trans.setDefaultLang('fr');

    let language = this.helper.website_data.language_list[Number(index)].code;
    let code = this.helper.website_data.language_list[Number(index)].code;
    helper.trans.use(code);
    this.helper.website_data.selected_language = language;
    const browserLang = helper.trans.getBrowserLang();
  }

  async ngOnInit() {
    this.helper.loader_service.main_loading = true;
    var res_data = await this.helper.http_post_method_requester(
      'api/store/check_and_get_domain_data',
      {},
      false,
      false,
      false,
      false
    );

    // var googleapisfont = this._documentRef.createElement("link");
    // googleapisfont.rel = "preconnect";
    // googleapisfont.href="https://fonts.googleapis.com";
    // this.elementRef.nativeElement.appendChild(googleapisfont);

    // var fontgstatic = this._documentRef.createElement("link");
    // fontgstatic.rel = "preconnect";
    // fontgstatic.href="https://fonts.gstatic.com";
    // this.elementRef.nativeElement.appendChild(fontgstatic);

    var s = this._documentRef.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.defer = true;
    s.innerHTML =
      'var stripe = Stripe("' +
      res_data.payment_key_id +
      '");var elements = stripe.elements();';
    this.elementRef.nativeElement.appendChild(s);

    var hotjar = this._documentRef.createElement('script');
    hotjar.type = 'text/javascript';
    hotjar.async = true;
    hotjar.defer = true;
    hotjar.innerHTML =
      "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1121668,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
    this.elementRef.nativeElement.appendChild(hotjar);

    var hotjar2 = this._documentRef.createElement('script');
    hotjar2.type = 'text/javascript';
    hotjar2.async = true;
    hotjar2.defer = true;
    hotjar2.innerHTML = "window.hj('identify', '1121668', {});";

    if (res_data.success) {
      this.helper.website_data.time_format = res_data.time_format ?? 'HH:mm';
      this.helper.website_data.detail = res_data.website_detail;

      this.helper.website_data.detail.store_time.forEach((day: any) => {
        day.day_time.forEach((time: any) => {
          let open_times = time.store_open_time.split(':');
          let close_times = time.store_close_time.split(':');
          time.store_open_time1 = new Date(
            new Date().setHours(open_times[0], open_times[1], 0, 0)
          );
          time.store_close_time1 = new Date(
            new Date().setHours(close_times[0], close_times[1], 0, 0)
          );
        });
      });
      console.log(this.helper.website_data.detail.store_time);

      this.helper.website_data.detail.site_logo =
        this.helper.image_base_url + res_data.website_detail.site_logo;
      this.helper.website_data.detail.store_id = res_data.website_detail._id;
      this.helper.website_data.detail.city_id = res_data.website_detail.city_id;
      // if(this.helper.router.url == '/hopper' || this.helper.router.url == '/partner' || this.user_helper.router.url == '/lets-hope-together'){
      // this.helper.router.navigate(['/']);
      // }
      this.helper.common_data.cart_data.timezone = res_data.timezone;
      this.helper.common_data.cart_data.server_date = res_data.server_time;
      this.delivery_setting_service.is_emergency = res_data.is_emergency;
      this.delivery_setting_service.emergency_close_till =
        res_data.emergency_close_till;
      let date: any = res_data.server_time;
      date = new Date(date).toLocaleString('en-US', {
        timeZone: res_data.timezone,
      });
      date = new Date(date);
      this.delivery_setting_service.check_open(
        this.helper.website_data.detail,
        date,
        true,
        true
      );

      var font = this._documentRef.createElement('link');
      font.rel = 'stylesheet';
      font.href = res_data.website_detail.font_url;
      this.elementRef.nativeElement.appendChild(font);
      document.documentElement.style.setProperty(
        '--body-fonts-typo',
        res_data.website_detail.font_name
      );

      if (res_data.language && !localStorage['language']) {
        this.helper.website_data.selected_language = res_data.language;
        this.helper.trans.use(res_data.language);
        let index = this.helper.website_data.language_list.findIndex(
          (x) => x.code == res_data.language
        );
        if (index == -1) {
          index = 0;
        }
        localStorage.setItem('language', index.toString());
      }
    } else {
      this.helper.website_data.detail = {
        site_color: '',
        site_desc: '',
        site_logo: '',
        store_id: '',
        name: '',
        address: '',
        phone: '',
        country_phone_code: '',
        city_id: '',
      };
      if (this.helper.router.url == '/') {
        setTimeout(() => {
          this.helper.router.navigate(['/home']);
        });
      }
    }
    var color = '#9bd1ef';
    if (this.helper.website_data.detail.site_color) {
      color = this.helper.website_data.detail.site_color;
    }

    document.documentElement.style.setProperty('--theme-primary-color', color);
    document.documentElement.style.setProperty(
      '--theme-secondary-color',
      color + '66'
    );
    document.documentElement.style.setProperty(
      '--theme-third-color',
      color + '0D'
    );
    document.documentElement.style.setProperty(
      '--theme-fourth-color',
      color + '26'
    );

    let cart_unique_token = localStorage.getItem('cart_unique_token');
    if (
      cart_unique_token == '' ||
      cart_unique_token == null ||
      cart_unique_token == undefined
    ) {
      localStorage.setItem(
        'cart_unique_token',
        this.helper.generatorRandomChar(32)
      );
    }

    var current_location: any = localStorage.getItem('current_location');
    if (current_location) {
      current_location = JSON.parse(current_location);
      this.helper.common_data.cart_data.destination_address.address =
        current_location.address;
      this.helper.common_data.cart_data.address = current_location.address;
      this.helper.common_data.cart_data.destination_address.location = [
        current_location.latitude,
        current_location.longitude,
      ];
    }
    console.log(1);
    this.helper.common_data.observable.next({});
    this.cart_service.get_cart();
  }
}
